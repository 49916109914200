<template>
  <section class="text-center pad-y-60 pad-b-30">
    <div class="container">
      <div class="heading" data-aos="fade-down">
        <h2 class="font-secondary text-teal-dark title fs-40">Om Swastiastu</h2>
        <!-- <div class="text-uppercase sub-title" style="font-size: 1.25rem; letter-spacing: 2px">Salam Sejahtera</div> -->
      </div>

      <div
        class="font-weight-lighter mar-bottom-25"
        data-aos="fade-right"
        data-aos-delay="300"
      >
        <!-- <div class="mb-2">
          Tuhan membuat segala sesuatu indah pada waktunya. Indah saat Dia
          mempertemukan, Indah saat Dia menumbuhkan kasih, dan indah saat Dia
          Mempersatukan putra putri kami dalam suatu ikatan pernikahan Kudus
        </div>
        <div>
          Dengan segala kerendahan hati dan dengan ungkapan syukur atas karunia
          Tuhan, kami mengundang Bapak/Ibu/Saudara/i untuk menghadiri Acara
          Pernikahan putra-putri kami yang akan diselenggarakan pada :
        </div> -->
        <div>
          Atas Asung Kertha Wara Nugraha Ida Sang Hyang Widhi Wasa / Tuhan Yang
          Maha Esa, kami bermaksud mengundang Bapak/Ibu/Saudara/i, pada acara
          Pawiwahan (Pernikahan) putra-putri kami pada :
        </div>
      </div>

      <div class="row justify-content-center">
        <div
          class="text-center col-md-4 col-12 mar-bottom-25"
          data-aos="fade-right"
          data-aos-delay="500"
        >
          <div>
            <img
              src="@/assets/icons/calendar-2.svg"
              style="width: 100%; max-height: 50px; opacity: .6"
            />
            <div class="font-weight-lighter mt-3 text-uppercase text-muted">
              HARI / TANGGAL
            </div>
            <div class="font-weight-bold fs-18">
              {{ $moment($parent.time.start).format("dddd, DD MMMM YYYY") }}
            </div>
          </div>
        </div>
        <div
          class="text-center col-md-4 col-12 mar-bottom-25"
          data-aos="fade-down"
          data-aos-delay="500"
        >
          <div>
            <img
              src="@/assets/icons/clock-2.svg"
              style="width: 100%; max-height: 50px; opacity: .6"
            />
            <div class="font-weight-lighter mt-3 text-uppercase text-muted">
              WAKTU
            </div>
            <div class="font-weight-bold fs-18">
              <div>RESEPSI SESI {{ $parent.session }}</div>
              {{ $moment($parent.time.start).format("HH.mm") }} -
              {{ $parent.time.end }}
            </div>

            <div class="d-md-none">
              Untuk mentaati protokol kesehatan, dihimbau untuk datang sesuai SESI kedatangan jam undangan.
            </div>
          </div>
        </div>

        <div
          class="text-center col-md-4 col-12 mar-bottom-25"
          data-aos="fade-left"
          data-aos-delay="500"
        >
          <div>
            <img
              src="@/assets/icons/marker-2.svg"
              style="width: 100%; max-height: 50px; opacity: .6"
            />
            <div class="font-weight-lighter mt-3 text-uppercase text-muted">
              Lokasi
            </div>
            <div class="font-weight-bold fs-18" v-html="$parent.location"></div>
            <!-- <div class="mt-2 padding">
              <a
                class="px-4 btn btn-primary has-icon align-items-center"
                target="_blank"
                :href="$parent.direction"
              >
                <i class="material-icons" style="font-size: .95rem">directions</i>
                <span class="mb-1 ml-1">Buka Peta</span>
              </a>
            </div> -->
          </div>
        </div>
      </div>

      <div class="d-none d-md-block mb-3 py-2" style="background: #eaeaea">
        Untuk mentaati protokol kesehatan, dihimbau untuk datang sesuai SESI kedatangan jam undangan.
      </div>

      <p class="font-weight-lighter" data-aos="fade-left" data-aos-delay="600">
        Merupakan suatu kehormatan dan kebahagiaan bagi kami apabila
        Bapak/Ibu/Saudara/i berkenan hadir dan memberikan doa restu kepada
        putra-putri kami. Atas kehadiran serta doa restu Bapak/Ibu/Saudara/i,
        kami sekeluarga mengucapkan terima kasih. Kami yang berbahagia.
      </p>

      <h3
        class="font-secondary text-teal-dark mb-0 mar-top-25"
        data-aos="fade-up"
        data-aos-delay="700"
      >
        {{ $parent.wedding.man.surname }} & {{ $parent.wedding.woman.surname }}
      </h3>
      <p class="font-secondary" data-aos="fade-up" data-aos-delay="800">
        Om Santhi Santhi Santhi Om
      </p>
    </div>
  </section>
</template>

<script>
export default {};
</script>